import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { closeNavActiveActionCreator, setNavActiveActionCreator } from '../../redux/NavActiveSlice.js';
import { closeProjectShowingActionCreator } from '../../redux/ProjectShowingSlice.js';
import { useEffect, useState } from 'react';

const NavBarContainer = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 2000;
  padding: 0 1.15rem;
  gap: 10px;

  @media (max-width: 1000px) {
    padding: 1.15rem;
    
    position: fixed;
    top: 0;

  }
`;

const NavBarElementContainer = styled.p`
  margin: 0;
  word-spacing: 1rem;
  opacity: ${props => props.$active || props.$opacity ? 0.05 : 1};
  transition: opacity 0.5s;
  cursor: pointer;
  width: ${props => props.$width || '10%'};
  text-align: right;
  
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const NavBarElementContainerFirst = styled(NavBarElementContainer)`
  z-index: 10000;
  text-align: left;


  @media (max-width: 1000px) {
    width: 40%;
    word-spacing: 0.2rem;
  }
`;

const RightElementsMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => `calc(${props.$contactContainerHeight}px - 1.6rem - 3px)`};

  z-index: ${props => props.$active ? 2002 : 0};
`;

const NavBar = () => {
  const isMobile = window.innerWidth < 768;
  const dispatch = useDispatch();
  const activeInfo = useSelector(state => state.navActive.active);
  const projectId = useSelector(state => state.projectShowing.project.id);
  const contact = useSelector(state => state.contact.contact);
  const [contactContainerHeight, setContactContainerHeight] = useState(0);
  
  useEffect(() => {
    if(isMobile && contact){
      const contactContainerHeightFirst = document.querySelector('.contact-container')?.clientHeight;
      setContactContainerHeight(contactContainerHeightFirst);
    }
  }, [isMobile, contact]);

  const activateText = (name) => {
    if(activeInfo === name){
      dispatch(closeNavActiveActionCreator());
      return;
    }
    dispatch(setNavActiveActionCreator(name));
  };

  const closeProject = () => {
    dispatch(closeProjectShowingActionCreator());
  };

  const navToHome = () => {
    dispatch(closeProjectShowingActionCreator());
    dispatch(closeNavActiveActionCreator());
  };

  return <NavBarContainer onClick={() => projectId ? closeProject() : null}>
    <NavBarElementContainerFirst onClick={() => navToHome()}>
      gr–os architects
    </NavBarElementContainerFirst>
   { !isMobile && <>
      <NavBarElementContainer style={{marginRight: '-10%'}} $width="20%" $opacity={!!projectId} $active={activeInfo === 'contact'} onClick={() => activateText('contact')}>  
        Contact
      </NavBarElementContainer>
      <NavBarElementContainer $active={activeInfo === 'info'} $width="20%" $opacity={!!projectId} onClick={() => activateText('info')}>  
        About
      </NavBarElementContainer>
    </> }
   { isMobile && <RightElementsMobile $active={activeInfo === 'contact'} $contactContainerHeight={contactContainerHeight}>
      <NavBarElementContainer style={{marginRight: '-10%'}} $width="20%" $opacity={!!projectId} $active={activeInfo === 'contact'} onClick={() => activateText('contact')}>  
        Contact
      </NavBarElementContainer>
      <NavBarElementContainer $active={activeInfo === 'info'} $width="20%" $opacity={!!projectId} onClick={() => activateText('info')}>  
        About
      </NavBarElementContainer>
    </RightElementsMobile> }
  </NavBarContainer>
}

export default NavBar;