import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { closeNavActiveActionCreator } from '../../../redux/NavActiveSlice.js';
import { closeProjectShowingActionCreator } from '../../../redux/ProjectShowingSlice.js';
import { useEffect, useState } from 'react';

const NavBarInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: ${props => props.$active ? '100vh' : '20%'};
  transition: height 0.1s, opacity 1s;
  z-index: 1;
  overflow-y: hidden;

  @media (max-width: 1000px) {
    flex-direction: column;
    height: calc(100vh - 1rem);
    width: 100%;
    align-items: flex-end;
    gap: 0.5rem;
    justify-content: flex-start;
    z-index: ${props => props.$active ? 6 : 'inherit'};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.$active ? 1 : 0.05};
  transition: height 0.01s, opacity 1s;

  width: 40%;
  height: ${props => props.$active ? '100vh' : '100%'};
  --mask: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255, 1) 10%);
  -webkit-mask:  ${props => props.$active ? null : 'var(--mask)'}; 
  mask: ${props => props.$active ? null : 'var(--mask)'};
  padding-left: 10px;
  overflow-y: ${props => props.$active ? 'scroll' : 'hidden'};
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 2.30rem);
    height: calc(100vh - 3rem);
    padding-left: 0;
    position: absolute;
    top: 0;
    left: 1.15rem;
    padding-top: ${props => `calc(${props.$top}px - 2rem)`};
    margin-top: 2.5rem;
    padding-bottom: ${props => props.$os === 'iOS' ? '5.15rem' : '1.15rem'};

  }
`;

const ContactContainerOutside = styled.div`
  opacity: ${props => props.$active ? 1 : 0.05};
  transition: opacity 1s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }
  @media (max-width: 1000px) {
    width: fit-content;
    padding-top: 1.3rem;
    z-index: ${props => props.$active ? 2001 : 0};
    p {
      margin-top: -2px;
    }
  }
`;

const TextAndPhotoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: baseline;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

  const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const PWithIndent = styled.p`
  text-indent: 1.8rem;
`;

const PWithLessIndent = styled.p`
  text-indent: 1.5rem;
`;

const PWithIndentMargin = styled(PWithIndent)`
  margin: 1rem 0 !important;
`;

const Image = styled.img`
  position: absolute;
  right: 1.15rem;
  bottom: 1.15rem;
  height: 55vh;
  align-self: flex-end;

  @media (max-width: 1000px) {
    height: auto;
    width: 100%;
    position: relative;
    right:inherit;
    bottom: inherit;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
`;

const ContainerTeamAndCredits = styled.div`
  display: flex;
  flex-direction: column;  
`;


const ContactClose = styled.div`
  width: 100px;
  height: 50px;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;


const NavBarInfo = ({os}) => {
  const isMobile = window.innerWidth < 768;
  const project = useSelector(state => state.projectShowing.project);
  const contact = useSelector(state => state.contact.contact);
  const {about} = useSelector(state => state.about);
  const activeInfo = useSelector(state => state.navActive.active);
  const dispatch = useDispatch();
  const [contactContainerHeight, setContactContainerHeight] = useState(0);
  const [renderAbout, setRenderAbout] = useState(isMobile ? false : true);

  // texts
  const [firstText, setFirstText] = useState('');
  const [remainTexts, setRemainTexts] = useState([]);
  const [team, setTeam] = useState([]);
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    if(isMobile && contact){
      const contactContainerHeighFirst = document.querySelector('.contact-container')?.clientHeight;
      setContactContainerHeight(contactContainerHeighFirst);
      if(contactContainerHeighFirst){
        setRenderAbout(true);
      }
    }
  }, [isMobile, contact]);

const formatAboutText = (about) => {
  const firstText = about.split('\n')[0];
  const remainTexts = about.split('\n').slice(1);
  setFirstText(firstText);
  setRemainTexts(remainTexts);
}

const formatTeamAndCredits = (about) => {
  const team = Object.values(about.team);
  const credits = Object.values(about.credits);
  setTeam(team);
  setCredits(credits);
}

  useEffect(() => {
    if(about){
      formatAboutText(about.info);
      formatTeamAndCredits(about);
    }
  }, [about]);

  const closeText = () => {
    dispatch(closeNavActiveActionCreator());
  };

  const closeProject = () => {
    dispatch(closeProjectShowingActionCreator());
  };

  return <NavBarInfoContainer  className="container" onClick={project?.id ? () => closeProject() : null} $active={activeInfo === 'info'}>

    <ContactContainerOutside  className="contact-container" $active={activeInfo === 'contact'}> 
    {
      !isMobile && 
      <><p onClick={() => closeText()}>{contact?.name.replace('\n', ' ')}</p> 
      <PWithIndent onClick={() => closeText()}>{contact?.address.replace('\n', ' ')}</PWithIndent> 
      </>
    } 
    {
      isMobile &&
      <> <ContactClose onClick={() => closeText()}></ContactClose>
      <p onClick={() => closeText()}>{contact?.name}</p> 
      <PWithIndent onClick={() => closeText()}>{contact?.address}</PWithIndent>
      </>
    }
      <p><a href="mailto:juan@gr-os.com" rel="noreferrer" target="_blank">{contact?.mail} </a></p>
      <PWithLessIndent> <a href={contact?.web} rel="noreferrer" target="_blank" >{contact?.webDisplayed}</a> </PWithLessIndent>
    </ContactContainerOutside>
    {renderAbout && <InfoContainer $os={os} $top={contactContainerHeight} onClick={() => closeText()} $active={activeInfo === 'info'}>
      <p>
        {firstText}
      </p>

      {remainTexts.map((text, index) => 
      <PWithIndent key={index}>
        {text}
      </PWithIndent>
      )}
      <TextAndPhotoContainer>
        <TextContainer>
          {isMobile && <> 
            <ContainerTeamAndCredits>
              <PWithIndentMargin>Team</PWithIndentMargin>
              <p>
                {team && team.map((member, index) =>
                  <span key={index}>{member}<br/></span>
                )}
              </p>
            </ContainerTeamAndCredits>
            <ContainerTeamAndCredits>
              <PWithIndentMargin>Credits</PWithIndentMargin>
              <p>
                {credits && credits.map((member, index) =>
                  <span key={index}>{member}<br/></span>
                )}
              </p>
            </ContainerTeamAndCredits> 
          </> }
          {!isMobile && <>
            <PWithIndentMargin>Team</PWithIndentMargin>
            <p>
              {team && team.map((member, index) =>
                <span key={index}>{member}<br/></span>
              )}
            </p>
            <PWithIndentMargin>Credits</PWithIndentMargin>
            <p>
              {credits && credits.map((member, index) =>
                <span key={index}>{member}<br/></span>
              )}
            </p>
          </> }
        </TextContainer>
        <Image src={about?.image}></Image>
      </TextAndPhotoContainer>
    </InfoContainer> }
  </NavBarInfoContainer>
}

export default NavBarInfo;
