import { createSlice } from '@reduxjs/toolkit';


const AboutSlice = createSlice({
  name: 'navActive',
  initialState: {
    about: null,
  },
  reducers: {
    setAbout: (state, action) => {
      state.about = action.payload;
    }
  }
});

export const { setAbout: setAboutActionCreator } = AboutSlice.actions;

export default AboutSlice.reducer;