import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import './insideSlider.css';

import { useSelector } from 'react-redux';
import { Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { TitleContainer, TitleItem } from '../../../Slider/Slider.js';

const Image = styled.img`
  width: ${(props) => (props.$isvertical ? 'auto' : '100%')};
  height: ${(props) => (props.$isvertical ? '100%' : 'auto')};

  @media (max-width: 1000px) {
    width: 100%;
    height: auto;
  }
`;

const Control = styled.div`
  position: absolute;
  height: 100%;
  width: 49%;
`;

const ToLeft = styled(Control)`
  left: 0;
`;

const ToRight = styled(Control)`
  right: 0;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2.4rem);
  width: calc(100vh - 3.4rem);
  scrollbar-width: none;
  /* Hide scrollbar in Firefox */
  -ms-overflow-style: none;


  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 3rem;
  }
`;

const TitleContainerWithOpacity = styled(TitleContainer)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: ${(props) => props.$width}px;
  align-self: flex-end;
  vertical-align: baseline;
  opacity: 1;
  padding-bottom: 5px;
  opacity: ${(props) => props.$width ? 1 : 0};
  transition: none;
  @media (max-width: 1000px) {
    align-self: center;
  };
`;

const ButtonsContainer = styled.div`
  padding-top: 5px;
  margin-bottom: -3px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: ${(props) => props.$width}px;
  align-self: flex-end;
  vertical-align: baseline;
  opacity: ${(props) => props.$width ? 1 : 0};
  transition: none;

  @media (max-width: 1000px) {
    align-self: center;
    padding-top: 10px;
  };
`;

const ButtonText = styled.p`
  cursor: pointer;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
`;

const TitleNumbers = styled(TitleItem)`
  display: flex;
  justify-content: flex-end;
  width: 3rem;
`;


const InsideSlider = () => {
  const project = useSelector((state) => state.projectShowing.project);
  const sliderRef = useRef(null);
  const [imageData, setImageData] = useState([]);
  const [firstImageWidth, setFirstImageWidth] = useState(0);

   useEffect(() => {
    const loadImages = async () => {
      const data = await Promise.all([...project.images, ...project.planos].map(src => {
        return new Promise(resolve => {
          const img = new window.Image();
          img.src = src;
          img.onload = () => {
            const isVertical = img.naturalHeight > img.naturalWidth;
            resolve({ width: img.naturalWidth, src, isVertical });
          };
        });
      }));

      setImageData([...data]);
    };

    loadImages();
  }, [project]);

  useEffect(() => {
    const firstImage = document.querySelector('.image-0');
    if (firstImage) {
      setFirstImageWidth(firstImage.clientWidth);
    }

  }, [imageData]);

   const handlePrev = () => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  };

  const handleNext =() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  };

  return (
     imageData.length > 0 &&
    <SliderContainer className='slider-container'>
        <TitleContainerWithOpacity $width={firstImageWidth}>
          <TitleItem>{project.title}</TitleItem>
          <TitleNumbers className='counter'></TitleNumbers>
        </TitleContainerWithOpacity>
      
      <Swiper
      ref={sliderRef}
      direction={'horizontal'}
      slidesPerView={1}
      slidesPerGroup={1}
      scrollbar={true}
      loop={true}
      mousewheel={{  
        forceToAxis: true,
      }}
      navigation={true}
      modules={[Scrollbar, Mousewheel, Pagination, Navigation]}
      pagination={{
        clickable: true,
        el: '.counter',
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          if(Number.isNaN(current)){
            return 1 + '/' + (total);
          } else {
            return current + '/' + (total); 
          }
        }
      }}
      >
        {imageData.map((image, index) => (
          <SwiperSlide className='slide' key={index}>
            <ToLeft onClick={() => handlePrev()}></ToLeft>
              <Image className={`image-${index}`} src={image.src}  $isvertical={image.isVertical}/>
            <ToRight onClick={() => handleNext()}></ToRight>
          </SwiperSlide>
        ))}
      </Swiper>
      <ButtonsContainer $width={firstImageWidth}>
        <ButtonText onClick={() => handlePrev()}>Back</ButtonText>
        <ButtonText onClick={() => handleNext()}>Next</ButtonText>
      </ButtonsContainer>
      
    </SliderContainer> 
  );
};

export default InsideSlider;
