import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InsideSlider from './components/InsideSlider/InsideSlider.js';

const ProjectContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 2rem);
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 1rem;
  opacity: ${props => props.$opacity};
  transition: opacity 4s;
  z-index: ${props => props.$zIndex};
  padding-left: 1rem;
  gap: 10px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 2rem);
    padding: 0rem 1.15rem 1.15rem 1.15rem;
    overflow-y: scroll;
  }
`;

const TextContainer = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  --mask: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 5%);
  
  -webkit-mask: var(--mask); 
  mask: var(--mask);

  @media (max-width: 1000px) {
    height: fit-content;
    width: 100%;
    padding-top: 0;
    mask: none;
    padding-bottom: ${props => props.$os === 'iOS' ? '5.15rem' : '1.15rem'};
  }
`;

const Text = styled.p`
  margin: 0;
  overflow-y: scroll;
  padding-top: 50%; 
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: -3px;

  @media (max-width: 1000px) {
    height: 100%;
    width: 100%;
    padding-top: 0;
    padding-bottom: 1rem;
  }
`;

const ProjectGallery = () => {
  const project = useSelector(state => state.projectShowing.project);
  const [width, setWidth] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [zIndex, setZIndex] = useState(0);
  const [animationEnded, setAnimationEnded] = useState(false);

  useEffect(() => {  
    const imageSelected = document.querySelector('.image-selected');  
    const imageWidth = imageSelected?.clientWidth;

    if (project.id === 1 || project.animationStopped) {
      setAnimationEnded(true);
      setWidth(imageWidth);
        setZIndex(10);
        setOpacity(1);
      } else {
        setAnimationEnded(false);
      }
  }, [project]);

  return (
    <>
      {animationEnded && project.id && <ProjectContainer $zIndex={zIndex} $opacity={opacity} $width={width}>
        <InsideSlider></InsideSlider>
        <TextContainer>
          <Text>
            {project.text}
          </Text>
        </TextContainer>
      </ProjectContainer> }
    </>
  );
}

export default ProjectGallery;