import { createSlice } from '@reduxjs/toolkit';


const NavActiveSlice = createSlice({
  name: 'navActive',
  initialState: {
    active: null,
  },
  reducers: {
    setNavActive: (state, action) => {
      state.active = action.payload;
    },
    closeNavActive: (state) => {
      state.active = null;
    },
  }
});

export const { setNavActive: setNavActiveActionCreator, closeNavActive: closeNavActiveActionCreator } = NavActiveSlice.actions;

export default NavActiveSlice.reducer;