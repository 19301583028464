import styled from 'styled-components';
import NavBar from './components/NavBar/NavBar.js';
import NavBarInfo from './components/NavBar/NavBarInfo/NavBarInfo.js';
import PhotoSlider from './components/Slider/Slider.js';
import ProjectGallery from './components/ProjectGallery/ProjectGallery.js';
import { useDispatch, useSelector } from 'react-redux';
import { closeProjectShowingActionCreator } from './redux/ProjectShowingSlice.js';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { setProjectsActionCreatorg } from './redux/ProjectsSlice.js';
import { setAboutActionCreator } from './redux/AboutSlice.js';
import { setContactActionCreator } from './redux/ContactSlice.js';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  padding: 1.15rem;

  @media (max-width: 1000px) {
    padding: 0 1.15rem;
  }
`;

const Close = styled.p`
  cursor: pointer;
  margin: 0px;
  padding: 0;
  position: absolute;
  right: 1.15rem;
  top: 1.1rem;
  z-index: 3000;
  width: 5.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: flex-end;
`;


function App() {
  const dispatch = useDispatch();
  const projectId = useSelector(state => state.projectShowing.project.id);
  const {projects} = useSelector(state => state.projects);
 const [mobileOS, setMobileOS] = useState(null);

  useEffect(() => {
    setMobileOS(getMobileOperatingSystem())
  }, [])

  const formatProject = (project) => {
    return {...project.attributes,
      id: project.id,
      images: Object.values(project.attributes.images),
      planos: Object.values(project.attributes.planos)
    }
  }

  const orderProjectsByRealIndex = (projects) => {
    return projects.sort((a, b) => +a.priority - +b.priority)
  }

  useEffect(() => {
  const fetchingData = async () => {
    
    await axios.get(`${process.env.REACT_APP_API_URL}projects`,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
        }
      }
    )
    .then((response) => {
      if(response.data){
        const beautifiedData = response.data.data?.map((project) => formatProject(project));

        dispatch(setProjectsActionCreatorg(orderProjectsByRealIndex(beautifiedData)));
      }
    })

    await axios.get(`${process.env.REACT_APP_API_URL}about`,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
        }
      }
    )
    .then((response) => {
      if(response.data){
        dispatch(setAboutActionCreator(response.data.data[0].attributes));
    }
    })

    await axios.get(`${process.env.REACT_APP_API_URL}contacts`,
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` 
        }
      }
    )
    .then((response) => {
      if(response?.data){
        dispatch(setContactActionCreator(response.data.data[0].attributes));
    }
    })
  }
  fetchingData();
  }, [dispatch])

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor ||window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
  }

    const handleImageClick = () => {
    dispatch(closeProjectShowingActionCreator())
  }
  return (
    <AppContainer>
     {projectId &&  <Close onClick={() => handleImageClick()}>x</Close> }
      <NavBar/>
      <NavBarInfo os={mobileOS}/>
      <PhotoSlider photos={projects} os={mobileOS}/>
      <ProjectGallery/>
    </AppContainer>
  );
}

export default App;
