import { createSlice } from '@reduxjs/toolkit';

const ContactSlice = createSlice({
  name: 'contact',
  initialState: {
    contact: null,
  },
  reducers: {
    setContact: (state, action) => {
      state.contact = action.payload;
    }
  }
});

export const { setContact: setContactActionCreator } = ContactSlice.actions;

export default ContactSlice.reducer;