import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { closeProjectShowingActionCreator, projectAnimationStoppedActionCreator, setProjectShowingActionCreator } from '../../redux/ProjectShowingSlice.js';
import { closeNavActiveActionCreator } from '../../redux/NavActiveSlice.js';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './PhotoSlider.css';

// import required modules
import { useDispatch, useSelector } from 'react-redux';

const Gallery = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: ${props => props.$active ? 'hidden' : 'scroll'};
  position: absolute;

  width: calc(100% - 2.3rem);
  height: calc(100% - 2.3rem);

  scrollbar-width: none;
  /* Hide scrollbar in Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar in Internet Explorer/Edge */
  align-items: flex-end;

  opacity: 1;
  transition: opacity 1s ease;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    z-index: 5;
    height: 100vh;
    padding-bottom: ${props => props.$os === 'iOS' ? '5.15rem' : '1.15rem'};
    gap: 1rem;
  }
`;

const FillerMobile = styled.div`
  padding-top: ${props => `calc((2 * ${props.$contactContainerHeight}px) - 1.6rem + 6px)`};
  width: 100%;
`;

const ImageRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  transition: opacity 1s ease;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    min-height: unset;
    height: unset;
    gap: 1rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  transition: opacity 0.5s ease;
  cursor: pointer;
  &:hover {
    opacity: ${props => props.$active ? 1 : 0.1};
    @media (max-width: 1000px) {
      opacity: 1;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.3rem;
  transition: opacity 0.5s ease;
  opacity: 0.1;

  @media (max-width: 1000px) {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;
  overflow-y: hidden;
  opacity: ${props => props.$opacity ? 0.1 : 1};
  transition: opacity 0.5s ease;
  &:hover {
    ${TitleContainer} {
      opacity: 1;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    overflow-y: visible;
    height: unset;
  }
`;

export const TitleItem = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 0.4rem;
  font-size: 0.95rem;
  
  @media (max-width: 1000px) {
    font-size: 0.85rem;
  }
`;

const PhotoSlider = ({ photos, os }) => {
  const [slides, setSlides] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [clickedId, setClickedId] = useState(null);
  const projectId = useSelector(state => state.projectShowing.project.id);
  const contact = useSelector(state => state.contact.contact);
  const navActive = useSelector(state => state.navActive.active);
  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 768;

  function splitArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  useEffect(() => {
    if (!projectId) {
      setClickedIndex(-1);
    }
  }, [projectId]);
  
  useEffect(() => {
    const slides = splitArray(photos, 3);
    setSlides(slides);
  }, [photos]);

  const [contactContainerHeight, setContactContainerHeight] = useState(0);
  
  useEffect(() => {
    if(isMobile && contact){
      const contactContainerHeightFirst = document.querySelector('.contact-container')?.clientHeight;
      setContactContainerHeight(contactContainerHeightFirst);
    }
  }, [isMobile, contact]);

  const handleImageClick = (index, slideIndex) => {
    if(projectId) {
      dispatch(closeProjectShowingActionCreator())
      return;
    }

    if (clickedIndex === -1) {
      setClickedIndex(index);
    } else if (clickedIndex === index) {
      setClickedIndex(-1);
    } else {
      setClickedIndex(index);
    }
    const animationDuration = 500; // Adjust duration as needed

    setTimeout(() => {
      dispatch(projectAnimationStoppedActionCreator())
      dispatch(closeNavActiveActionCreator());
    }, animationDuration);

    setClickedId(slides[slideIndex][index].id);
    dispatch(setProjectShowingActionCreator(slides[slideIndex][index]));
    dispatch(closeNavActiveActionCreator())
  };

  return (
    <>
    <Gallery $contactContainerHeight={contactContainerHeight} $active={navActive} $os={os}>
    {isMobile && <FillerMobile $contactContainerHeight={contactContainerHeight} />}
      {slides?.map((slide, slideIndex) => (
        <ImageRow key={slideIndex}>
          {slide.map((photo, photoIndex) => (
            <ImageContainer
              key={photoIndex}
              onClick={() => handleImageClick(photoIndex, slideIndex)}
              className={photo.id === clickedId ? 'image-selected' : ''}
              $opacity={navActive || !!projectId}
            >
              <TitleContainer>
                <TitleItem>{photo.title}</TitleItem>
                <TitleItem>{`1/${photo.images?.length + photo.planos?.length}`}</TitleItem>
              </TitleContainer>
              <Image $active={navActive} className={`image-${photo.id}`} src={photo.url} />
            </ImageContainer>
          ))}
        </ImageRow>
      ))}
    </Gallery>
    </>
  );
};

export default PhotoSlider;