import { configureStore } from '@reduxjs/toolkit'

import ProjectShowingSliceReducer from './ProjectShowingSlice'
import ProjectsSliceReducer from './ProjectsSlice'
import NavActiveSliceReducer from './NavActiveSlice'
import AboutSliceReducer from './AboutSlice'
import ContactSliceReducer from './ContactSlice'

export const store = configureStore({
  reducer: {
    navActive: NavActiveSliceReducer,
    projectShowing: ProjectShowingSliceReducer,
    projects: ProjectsSliceReducer,
    about: AboutSliceReducer,
    contact: ContactSliceReducer
  },
})