import { createSlice } from "@reduxjs/toolkit";

const ProjectShowing = createSlice({
  name: 'projectShowing',
  initialState: {
    project: {},
  },
  reducers: {
    setProjectShowing: (state, action) => {
      state.project = action.payload;
    },
    closeProjectShowing: (state) => {
      state.project = {};
    },
    projectAnimationStopped: (state) => {
      state.project = {...state.project, animationStopped: true};
    }
  }
});

export const { setProjectShowing: setProjectShowingActionCreator, closeProjectShowing: closeProjectShowingActionCreator, projectAnimationStopped: projectAnimationStoppedActionCreator } = ProjectShowing.actions;

export default ProjectShowing.reducer;
